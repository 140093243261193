export const haierIcons = {
  whatsapp: { type: 'Chat', name: '客服', en_name: 'Chat', url: '869043a9.png' },
  cart: { type: 'Cart', name: '购物车', en_name: 'Cart', url: '5746dfa3.png' },
  top: { type: 'Top', name: '返回顶部', en_name: 'Top', url: '6cf95dac.png' }
}


export const userInfoIcons = {
  Whatsapp: { type: 'Whatsapp', name: '客服', en_name: 'Whatsapp', url: '536dc1a3.png'},
  Email: { type: 'Email', name: '邮箱', en_name: 'Email', url: '9238534c.png' },
  Support: { type: 'Support', name: '帮助', en_name: 'Support', url: '0da321fe.png' }
}

/**
 * 订单状态
 * 待支付,支付中,待发货,已发货,已收货,关闭,支付失败
 */
export const OrderStatus = {
  NOPAID: 0,
  PAYING: 1,
  UNDISTRIBUTED: 2,
  DISTRIBUTED: 3,
  RECEIPTED: 4,
  CLOSED: 5,
  PAYMENT_FAILED: 6
}  


export const OrderStatus2 = [
  // { type: 'NOPAID', typeName: '待支付', lable: 'Unpaid', icon: 'https://cdn.qingmeidata.com/e300adcf.png' },
  // { type: 'PAYING', typeName: '支付中', lable: 'Paying', icon: 'https://cdn.qingmeidata.com/29f5a3c2.png' },
  { type: 'NOPAID', typeName: '待支付', lable: 'Unpaid', icon: 'https://cdn.qingmeidata.com/719107a5.png'},
  { type: 'PAYING', typeName: '支付中', lable: 'Paying', icon: 'https://cdn.qingmeidata.com/719107a5.png' },
  { type: 'UNDISTRIBUTED', typeName: '待发货', lable: 'To Ship', icon: 'https://cdn.qingmeidata.com/b316c941.png' },
  { type: 'DISTRIBUTED', typeName: '已发货', lable: 'To Receive', icon: 'https://cdn.qingmeidata.com/e634f07c.png' },
  { type: 'RECEIPTED', typeName: '已收货', lable: 'To Review', icon: 'https://cdn.qingmeidata.com/63bebf08.png' },
  { type: 'CLOSED', typeName: '关闭', lable: 'Closed', icon: 'https://cdn.qingmeidata.com/ede79567.png' },
  { type: 'PAYMENT_FAILED', typeName: '支付失败', lable: 'Payment Failed', icon: 'https://cdn.qingmeidata.com/719107a5.png' }
]